import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import * as Ink from '@carta/ink';
import { LiveProvider, LivePreview } from 'react-live';
import moment from 'moment';

import { formatCode } from '../../helpers';
import StyledLink from './StyledLink';

export type PreviewCardProps = {
  previewImageSource?: string;
  previewCode?: string;
  previewWithCheckerboard?: boolean;
  title: string;
  bubble?: ReactNode;
  text?: string;
  actionLabel: string;
  actionHref?: string;
  actionCallback?: () => unknown;
  hasPadding?: boolean;
};

const PreviewWrapper = styled.div`
  display: flex;
  height: 240px;
  overflow-x: hidden;
  place-content: center;
  ${({ theme }) =>
    theme.square &&
    css`
      width: 100px;
      height: 100px;
    `};
  ${({ theme }) =>
    theme.withCheckerboard &&
    css`
      background-image: linear-gradient(45deg, rgb(249, 249, 250) 25%, transparent 25%),
        linear-gradient(135deg, rgb(249, 249, 250) 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, rgb(249, 249, 250) 75%),
        linear-gradient(135deg, transparent 75%, rgb(249, 249, 250) 75%);
      background-size: 20px 20px;
      background-position: 0px 0px, 10px 0px, 10px -10px, 0px 10px;
    `};
`;

const PreviewCardWrapper = styled.div`
  height: 100%;

  ${({ theme }) =>
    theme.hasPadding &&
    css`
      padding-right: 20px;
      padding-bottom: 20px;
    `};
`;

const PreviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  place-self: center;
  min-width: stretch;
  margin: 20px;

  & > div {
    min-width: stretch;
    place-self: center;
  }
`;

export const renderPreview = (previewWithCheckerboard = false, previewCode, square = false, image?) => {
  return (
    <PreviewWrapper theme={{ withCheckerboard: previewWithCheckerboard, square }}>
      {previewCode && (
        <LiveProvider code={formatCode(previewCode, 'jsx')} scope={{ ...Ink, moment, React }}>
          <PreviewContentWrapper>
            <LivePreview />
          </PreviewContentWrapper>
        </LiveProvider>
      )}
      {image && image}
    </PreviewWrapper>
  );
};

const PreviewCard = (props: PreviewCardProps) => {
  const { previewWithCheckerboard, previewCode, title, text, actionLabel, actionHref, bubble, hasPadding } = props;

  return (
    <PreviewCardWrapper theme={{ hasPadding }}>
      <Ink.Tile height="full">
        {previewCode && (
          <Ink.VStack dividers="regular">
            {renderPreview(previewWithCheckerboard, previewCode)}
            <Ink.Box>
              <Ink.HStack align="distributed">
                <Ink.Heading variant="heading-3">{title}</Ink.Heading>
                {bubble && bubble}
              </Ink.HStack>
              <Ink.Text>{text}</Ink.Text>
              {actionHref && <StyledLink to={actionHref}>{actionLabel}</StyledLink>}
            </Ink.Box>
          </Ink.VStack>
        )}
        {!previewCode && (
          <Ink.Box>
            <Ink.Heading variant="heading-3">{title}</Ink.Heading>
            <Ink.Text>{text}</Ink.Text>
            {actionHref && <StyledLink to={actionHref}>{actionLabel}</StyledLink>}
          </Ink.Box>
        )}
      </Ink.Tile>
    </PreviewCardWrapper>
  );
};

export default PreviewCard;
